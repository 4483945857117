/**
 * @license
 * MyFonts Webfont Build ID 3916852, 2020-06-22T02:11:34-0400
 * 
 * The fonts listed in this notice are subject to the End User License
 * Agreement(s) entered into by the website owner. All other parties are 
 * explicitly restricted from using the Licensed Webfonts(s).
 * 
 * You may obtain a valid license at the URLs below.
 * 
 * Webfont: FreightSansProSemibold-Regular by GarageFonts
 * URL: https://www.myfonts.com/fonts/garagefonts/freight-sans-pro/semibold/
 * Copyright: Copyright &amp;#x00A9; 2004-2009, Joshua Darden &amp;amp; Phil&#x27;s Fonts, Inc. All rights reserved. Published by Garagefonts USA 301-879-9222 www.garagefonts.com
 * 
 * Webfont: FreightSansProBold-Regular by GarageFonts
 * URL: https://www.myfonts.com/fonts/garagefonts/freight-sans-pro/bold/
 * Copyright: Copyright &amp;#x00A9; 2004-2009, Joshua Darden &amp;amp; Phil&#x27;s Fonts, Inc. All rights reserved. Published by Garagefonts USA 301-879-9222 www.garagefonts.com
 * 
 * Webfont: FreightSansProBook-Regular by GarageFonts
 * URL: https://www.myfonts.com/fonts/garagefonts/freight-sans-pro/book/
 * Copyright: Copyright &amp;#x00A9; 2004-2010, Joshua Darden &amp;amp; Phil&#x27;s Fonts, Inc. All rights reserved. Published by Garagefonts USA 301-879-9222 www.garagefonts.com
 * 
 * 
 * 
 * © 2020 MyFonts Inc
*/


/* @import must be at top of file, otherwise CSS will not work */
@import url("//hello.myfonts.net/count/3bc434");
  
@font-face {
  font-family: 'FreightSansProBook-Regular';
  src: url('webFonts/FreightSansProBook-Regular/font.woff2') format('woff2'), url('webFonts/FreightSansProBook-Regular/font.woff') format('woff');
}
@font-face {
  font-family: 'FreightSansProBold-Regular';
  src: url('webFonts/FreightSansProBold-Regular/font.woff2') format('woff2'), url('webFonts/FreightSansProBold-Regular/font.woff') format('woff');
}
@font-face {
  font-family: 'FreightSansProSemibold-Regular';
  src: url('webFonts/FreightSansProSemibold-Regular/font.woff2') format('woff2'), url('webFonts/FreightSansProSemibold-Regular/font.woff') format('woff');
}
@font-face {
  font-family: 'Roboto';
  src: url('webFonts/Roboto-Regular/Roboto-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Roboto';
  src: url('webFonts/Roboto-Bold/Roboto-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}
