@import url(//hello.myfonts.net/count/3bc434);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.breadcrumbs {
  display: -webkit-flex;
  display: flex;
  height: 40px;
  -webkit-align-items: center;
          align-items: center;
}

.cropper-crop {
  border-radius: 8px;
}

.react-datepicker-popper {
  z-index: 2 !important;
}

.react-datepicker__header, .react-datepicker__day--selected, .react-datepicker__day--keyboard-selected {
  background-color: #32B265 !important;
}
.react-datepicker-wrapper{
  width: 100% !important;
}
.react-datepicker__day-name{
  color: white !important;
}
.react-datepicker__month-select, .react-datepicker__year-select{
  height: 25px;
  border: none;
}
.react-datepicker__month-select, .react-datepicker__year-select:focus,
.react-datepicker__month-select, .react-datepicker__year-select:hover {
  outline: none;
  border:none;
}
.react-datepicker__header__dropdown{
  margin-top: 5px;
}
.react-datepicker__current-month{
  color:white !important;
}
.react-datepicker__navigation:focus, .react-datepicker__navigation:hover{
    outline: none;
}
/**
 * @license
 * MyFonts Webfont Build ID 3916852, 2020-06-22T02:11:34-0400
 * 
 * The fonts listed in this notice are subject to the End User License
 * Agreement(s) entered into by the website owner. All other parties are 
 * explicitly restricted from using the Licensed Webfonts(s).
 * 
 * You may obtain a valid license at the URLs below.
 * 
 * Webfont: FreightSansProSemibold-Regular by GarageFonts
 * URL: https://www.myfonts.com/fonts/garagefonts/freight-sans-pro/semibold/
 * Copyright: Copyright &amp;#x00A9; 2004-2009, Joshua Darden &amp;amp; Phil&#x27;s Fonts, Inc. All rights reserved. Published by Garagefonts USA 301-879-9222 www.garagefonts.com
 * 
 * Webfont: FreightSansProBold-Regular by GarageFonts
 * URL: https://www.myfonts.com/fonts/garagefonts/freight-sans-pro/bold/
 * Copyright: Copyright &amp;#x00A9; 2004-2009, Joshua Darden &amp;amp; Phil&#x27;s Fonts, Inc. All rights reserved. Published by Garagefonts USA 301-879-9222 www.garagefonts.com
 * 
 * Webfont: FreightSansProBook-Regular by GarageFonts
 * URL: https://www.myfonts.com/fonts/garagefonts/freight-sans-pro/book/
 * Copyright: Copyright &amp;#x00A9; 2004-2010, Joshua Darden &amp;amp; Phil&#x27;s Fonts, Inc. All rights reserved. Published by Garagefonts USA 301-879-9222 www.garagefonts.com
 * 
 * 
 * 
 * © 2020 MyFonts Inc
*/


/* @import must be at top of file, otherwise CSS will not work */
  
@font-face {
  font-family: 'FreightSansProBook-Regular';
  src: url(/static/media/font.19d0f666.woff2) format('woff2'), url(/static/media/font.74aeb674.woff) format('woff');
}
@font-face {
  font-family: 'FreightSansProBold-Regular';
  src: url(/static/media/font.eb57b393.woff2) format('woff2'), url(/static/media/font.d24196a9.woff) format('woff');
}
@font-face {
  font-family: 'FreightSansProSemibold-Regular';
  src: url(/static/media/font.ecdbfcb4.woff2) format('woff2'), url(/static/media/font.ed9222a9.woff) format('woff');
}
@font-face {
  font-family: 'Roboto';
  src: url(/static/media/Roboto-Regular.6bede58e.ttf) format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Roboto';
  src: url(/static/media/Roboto-Bold.17451a4c.ttf) format('truetype');
  font-weight: bold;
  font-style: normal;
}

.appLoader_loader__26KVY{border:5px solid #fff;border-top:5px solid #32B265;border-radius:50%;width:40px;height:40px;-webkit-animation:appLoader_spin__2BQBp 2s linear infinite;animation:appLoader_spin__2BQBp 2s linear infinite;position:fixed;top:45%;left:48%;z-index:1302}@-webkit-keyframes appLoader_spin__2BQBp{0%{-webkit-transform:rotate(0deg);transform:rotate(0deg)}100%{-webkit-transform:rotate(360deg);transform:rotate(360deg)}}@keyframes appLoader_spin__2BQBp{0%{-webkit-transform:rotate(0deg);transform:rotate(0deg)}100%{-webkit-transform:rotate(360deg);transform:rotate(360deg)}}.appLoader_appOverlay__2ziiu{position:fixed;width:100%;height:100%;background-color:#ffffff;padding:10px;opacity:0.5;z-index:1301}

